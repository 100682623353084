

import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useHistory, Redirect } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";


import { db, auth } from '../../../FireBaseConfig'
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { AuthCredential, createUserWithEmailAndPassword, signInAnonymously, signInWithCredential, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

import { ref, set, get } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from "index";


function SignIn() {
  // Chakra color mode
  const histroy = useHistory();
  const [isSignin, setIsSignIn] = useState(true);
  const { user, setUser, isLoading, setIsLoading } = useContext(GlobalContext);
  let [email, password, telegram, username, phonenumber, accountType, walletAddress] = ['', '', '', '', '', 'Affliate', ''];

  const EmailRef = useRef(null);
  const PasswordRef = useRef(null); 

  const refEmail = useRef(null);
  const refPassword = useRef(null)
  const refTelegram = useRef(null)
  const refUserName = useRef(null)
  const refPhoneNumber = useRef(null)
  const refAccountType = useRef("Affliate");
  const refWalletAddress = useRef(null);

  //accountType = 'Affliate';
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
   
  if (user != null) {
    <Redirect to="/dashboard" />
  }
  if (!isSignin) {
    return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>

        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'

          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign Up
            </Heading>

          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>


              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='mail@simmmple.com'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { email = e.target.value }}
              />


              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                User Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='name'
                placeholder='XYZ'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { username = e.target.value }}
              />

              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Phone Number<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='name'
                placeholder='+971xxxxxx'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { phonenumber = e.target.value }}
              />

              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Telegram<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='name'
                placeholder='@coinfluence'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { telegram = e.target.value }}
              />

              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Account Type<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='outline'
                mb='12px'
                fontWeight='500'
                size='lg'

                defaultValue={'Affliate'}
                onChange={(e) => { accountType = e.target.value }}>

                <option value='Affliate'>Affliate Account</option>
                <option value='Exchange'>Exchange Account</option>
              </Select>


              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Wallet Address<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='name'
                placeholder='0x0000000000'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { walletAddress = e.target.value }}
              />

              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='4px'>
                Password<Text color={brandStars}>* (should be atleast 6 characters)</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='name'
                placeholder='********'
                mb='12px'
                fontWeight='500'
                size='lg'
                onChange={(e) => { password = e.target.value }}
              />

              <Flex justifyContent='space-between' align='center' mb='24px'>
                <Button
                  fontSize='sm'
                  variant='brand'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  mb='24px' onClick={() => {
                    setIsLoading(true);
                    
                    if (username === '' || password === '' || email === '' || telegram === '' || walletAddress === '' || phonenumber === '' || accountType === '') {
                      setIsLoading(false);
                      toast.info("Please fill all the feilds with correct information", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });

                    } else {
                      createUserWithEmailAndPassword(auth, email, password).then(UserData => {
                        const user = UserData.user;
                        set(ref(db, 'Users/' + user.uid), {
                          UserName: username,
                          Email: email,
                          WalletAddress : walletAddress,
                          Role:accountType,
                          PhoneNumber:phonenumber,
                          Telegram:telegram,
                          PairTable:"null",
                          Status:"Pending",
                          UserBalance:0,
                          UUID:user.uid
                        }).then(()=>{
                          setIsLoading(false);
                      
                          toast.info("Request submitted ! Please wait for approval", {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }).catch(error=>{
                          setIsLoading(false);
                   
                          toast.error("Something went wrong ! Please contact Administrator", {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        })

                      }).catch(error => {
                        setIsLoading(false);
                        
                        toast.error("Something went wrong ! Please contact Administrator", {
                          position: "bottom-center",
                          autoClose: 3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      })
                    }
                  }} >
                  Sign Up
                </Button>

              </Flex>

           
            <Text
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              color={'blue'}
              cursor={'pointer'}
              mb='24px' onClick={() => setIsSignIn(true)}>
              Already have a account !
            </Text>
          </Flex>
        </Flex>
        <ToastContainer position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
      </DefaultAuth>
    )
  }




  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>


  
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              ref={EmailRef}
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => { email = e.target.value }}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                ref={PasswordRef}
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => { password = e.target.value }}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>


            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px' onClick={() => {

                if (user == null && !isLoading) {
                  setIsLoading(true);
                  signInWithEmailAndPassword(auth, EmailRef.current.value, PasswordRef.current.value).then(userCredential => {
                    
                    get(ref(db, `Users/${userCredential.user.uid}`)).then((snapshot) => {
                      console.log(snapshot.val());
                      if (snapshot.exists()) {

                        const data = snapshot.val();
                      
                        setIsLoading(false);
                        if (data.Active === true) {
                          setUser(userCredential.user.uid);
                        
                          histroy.replace("/dashboard")
                          //console.log("Moving");

                          // <Navigate to="/dashboard"/>
                          // Redirect now
                        }else {
                          toast.info("Your account is blocked please contact admin!", {
                            position: "bottom-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                          setUser(null);
                        }

                        //console.log(data);
                      } else {
                        setIsLoading(false);
                        toast.info("Something went wrong ! Please contact Admin !!!!", {
                          position: "bottom-center",
                          autoClose: 2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                        //console.log("No data available");
                      }
                    }).catch((error) => {
                      setIsLoading(false);
                      console.log(error);
                      toast.info("Something went wrong ! Please contact Admin !!", {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                    //setUser(userCredential.user);
                    //setIsLoading(false);
                  }).catch(error => {
                    setIsLoading(false);
                    toast.error("Wrong email or Password", {
                      position: "bottom-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  })
                }
              }}>
              Sign In
            </Button>
            
        

        </Flex>
      </Flex>
      <ToastContainer position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
    </DefaultAuth>
  );
}

export default SignIn;
