// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJPAbZo_OcRU-Jlku8ESEmClld1VjltH8",
  authDomain: "tradeblocks-client.firebaseapp.com",
  databaseURL: "https://tradeblocks-client-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tradeblocks-client",
  storageBucket: "tradeblocks-client.appspot.com",
  messagingSenderId: "629207119375",
  appId: "1:629207119375:web:af4108fa865f88be4c557a",
  measurementId: "G-NYGESB31HB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase();
const auth = getAuth(app);
export {analytics,db,auth};