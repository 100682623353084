import React, { useState } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, withRouter } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider, useToast } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import LoadingOverlay from '@ronchalant/react-loading-overlay'
import {CircleLoader,DotLoader, HashLoader, PropagateLoader, RiseLoader, SyncLoader} from 'react-spinners'

export const GlobalContext = React.createContext(null);
LoadingOverlay.propTypes = undefined
const APP = () => {
  const [user, setUser] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  return (
    <GlobalContext.Provider value={{ user: user, setUser: setUser , isLoading:isLoading,setIsLoading:setIsLoading }} >
    <LoadingOverlay
      active={isLoading}
      styles={{
        overlay: (base) => ({
          ...base,
          height:'100vh',
          background: 'rgba(255, 255, 255, 0.95)'
        })
      }}
      spinner=
      {<PropagateLoader
        color="#6c44cd"
        size={25}
      />}
    >
     
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <ThemeEditorProvider>
              <BrowserRouter>
                <Switch>

                  <Route exact path={`/auth`} component={AuthLayout} />
                  <Route exact path={`/dashboard`} component={AdminLayout} />
                  <Route exact path="/"> 
                    <Redirect to="/auth"/>
                  </Route>
                   
                  {/* <Route path={`/rtl`} component={RTLLayout} /> */}
                  {/* <Redirect from='/' to='/auth' />  */}
                </Switch>
              </BrowserRouter>
            </ThemeEditorProvider>
          </React.StrictMode>
        </ChakraProvider>
   
    </LoadingOverlay>
    </GlobalContext.Provider>
  )
}
ReactDOM.render(
    <APP/>,
  document.getElementById("root")
);
