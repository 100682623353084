import {
    Box, Tabs, TabList, Tab, TabPanels, TabPanel, TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Tfoot,
    SimpleGrid, Tag, Menu, MenuButton, Button, MenuList, MenuItem,
    TagLabel
} from "@chakra-ui/react";
import LineChart from "components/charts/LineChart";
import {
    lineChartDataTotalSpent,
    lineChartOptionsTotalSpent,
} from "variables/charts";
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
} from '@chakra-ui/react'
import { useEffect, useState, useContext } from "react";

import { db, auth } from '../FireBaseConfig'

import { ref, set, get, onValue, onChildChanged } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from "index";
import { ChevronDownIcon } from "@chakra-ui/icons";

const UserDashboard = (userName) => {

    const [state, setState] = useState(null)
    const { user } = useContext(GlobalContext);
    const [isAdmin, setIsAdmin] = useState(false)
    const [selectedProject, setSelectedProject] = useState(0);
    const [projectList, setProjectList] = useState([]);
    useEffect(() => {

        get(ref(db, `Users/${user}`)).then((snapshot) => {
            let u = snapshot.val();
            setIsAdmin(u.isAdmin);
            if (u.isAdmin) {
                // // get(ref(db, `projects/`)).then((snapshot) => {

                // //     setProjectList(Object.keys(snapshot.val()));
                // //     setState(Object.values(snapshot.val()));
                // // })
                onValue(ref(db, `projects/`), (snapshot) => {
                    //  console.log(snapshot.val());
                    setProjectList(Object.keys(snapshot.val()));
                    setState(Object.values(snapshot.val()));
                });
            } else {
                // get(ref(db, `projects/${user}`)).then((snapshot) => {
                //     let data = [];
                //     data.push(snapshot.val());
                //     setState(data);
                // })

                onValue(ref(db, `projects/${user}`), (snapshot) => {

                    let data = [];
                    data.push(snapshot.val());
                    setState(data);
                });

            }

        })



    }, [])

    if (state === null) {
        return null;
    }
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {
                isAdmin
                &&
                <div style={{ padding: "5px", margin: '15px' }}>
                    <Menu >
                        <MenuButton as={Button} px={4}
                            py={2}
                            transition='all 0.2s'
                            borderRadius='md'
                            borderWidth='1px'
                            _hover={{ bg: 'gray.400' }}
                            _expanded={{ bg: 'blue.400' }}
                            _focus={{ boxShadow: 'outline' }}
                            colorScheme="pink"
                        >
                            {projectList[selectedProject]}
                        </MenuButton>
                        <MenuList>
                            {
                                projectList.map((v, i) => {
                                    return (
                                        <MenuItem onClick={() => { setSelectedProject(i) }} >{v}</MenuItem>
                                    )
                                })
                            }

                        </MenuList>
                    </Menu>
                </div>
            }
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, "2xl": 1 }}
                gap='20px'
                mb='20px'>

                <Tabs bgColor={"white"} px="5px" colorScheme="white" variant='enclosed'>
                    <TabList >

                        <Tab _selected={{ color: 'white', bg: '#ddc138' }}>Balance History</Tab>
                        <Tab _selected={{ color: 'white', bg: '#ddc138' }}>Exchanges</Tab>
                        <Tab _selected={{ color: 'white', bg: '#ddc138' }}>Trades</Tab>
                        <Tab _selected={{ color: 'white', bg: '#ddc138' }}>Volume History</Tab>
                    </TabList>
                    {

                        state &&
                        <TabPanels bgColor="white">


                            <TabPanel>
                                {BalanceHistory(state[selectedProject].balances_history)}
                                {/* {UserDataTable(["UID", "Email", "Amount", "Wallet Address", "Note", "TX"], ["Approve,Decline"], "Approved")} */}
                            </TabPanel>
                            <TabPanel>
                                {Exchanges(state[selectedProject].exchanges)}
                            </TabPanel>
                            <TabPanel>
                                {Trades(state[selectedProject].trades)}
                                {/* {UserDataTable(["UID", "Email", "Amount", "Wallet Address", "Note", "TX"], ["Approve,Decline"], "Approved")} */}
                            </TabPanel>
                            <TabPanel>
                                {Volumes(state[selectedProject].volume_history)}
                                {/* {UserDataTable(["UID", "Email", "Amount", "Wallet Address", "Note", "TX"], ["Approve,Decline"], "Approved")} */}
                            </TabPanel>






                        </TabPanels>
                    }
                </Tabs>
            </SimpleGrid>
        </Box>

    );
}


function Trades(trades) {
    if (trades === undefined) {
        return null
    }
    return (
        <TableContainer>
            <Table variant='striped' colorScheme='orange'>
                <Thead>
                    <Tr>
                        <Th>
                            Exchange
                        </Th>
                        <Th>
                            Start Time
                        </Th>
                        <Th>
                            End Time
                        </Th>
                        <Th>
                            Token Sold
                        </Th>
                        <Th>
                            Total USD
                        </Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {

                        Object.values(trades.sales).reverse().map((o, i) => {
                            var date1 = o.startTime.toString();
                            var date2 = o.endTime.toString();
                            var sd = new Date(parseInt(date1, 10));
                            var ed = new Date(parseInt(date2, 10));
                            //console.log(d.lo())

                            return (

                                <Tr>
                                    <Td>
                                        {o.exchange}
                                    </Td>
                                    <Td>
                                        {sd.toLocaleString()}
                                    </Td>
                                    <Td>
                                        {ed.toLocaleString()}
                                    </Td>
                                    <Td>
                                        {o.tokensSold}
                                    </Td>
                                    <Td>
                                        {o.total_usd}
                                    </Td>

                                </Tr>

                            )
                        })
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}



function Exchanges(exchange) {

    if (exchange === undefined) {
        return null
    }
    return (


        <Tabs bgColor={"white"} px="5px" colorScheme="purple" variant='enclosed'>
            <TabList>
                {
                    Object.keys(exchange).map((o) => {
                        return (
                            <Tab _selected={{ color: 'white', bg: 'green.500' }}>
                                {o}
                            </Tab>
                        )
                    })
                }
            </TabList>

            <TabPanels bgColor="white">
                {
                    Object.keys(exchange).map((o, i) => {

                        return (

                            <TabPanel>
                                {


                                    <StatGroup>
                                        {
                                            Object.keys(exchange[o].balances).map((key, i) => {

                                                return (
                                                    <Stat style={{ padding: '10px', margin: '4px', background: '#f5f4f4' }}>
                                                        <StatLabel>{key}</StatLabel>
                                                        <StatNumber>{exchange[o].balances[key]}</StatNumber>
                                                        <StatHelpText>
                                                            <StatArrow type='increase' />
                                                            0%
                                                        </StatHelpText>
                                                    </Stat>
                                                )
                                            })
                                        }



                                    </StatGroup>

                                }
                                <TableContainer>
                                    <Table variant='striped' colorScheme='orange'>
                                        <Thead>
                                            <Tr>
                                                <Th>
                                                    Pair
                                                </Th>
                                                <Th>
                                                    Is Active
                                                </Th>
                                                <Th>
                                                    Last Price
                                                </Th>
                                                <Th>
                                                    Spread
                                                </Th>
                                                <Th>
                                                    24H Volume
                                                </Th>
                                                <Th>
                                                    Boost Fees Today
                                                </Th>

                                            </Tr>{

                                            }
                                        </Thead>

                                        <Tbody>
                                            {


                                                Object.values(exchange[o].pairs).map((ob, i) => {

                                                    //console.log(d.lo())
                                                    //v++;
                                                    return (

                                                        <Tr>
                                                            <Td>
                                                                {ob.name}
                                                            </Td>
                                                            <Td>
                                                                {ob.active.toString()}
                                                            </Td>
                                                            <Td>
                                                                {ob.lastPrice}
                                                            </Td>
                                                            <Td>
                                                                {ob.spread + "%"}
                                                            </Td>
                                                            <Td>
                                                                {ob.Volume}
                                                            </Td>
                                                            <Td>
                                                                {ob.boostFeesToday}
                                                            </Td>


                                                        </Tr>

                                                    )

                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                            </TabPanel>

                        )
                    })
                }
            </TabPanels>



        </Tabs>

    )
}

function BalanceHistory(balance) {

    // const [balance,setBalance] = useState(balancee)
    if (balance === undefined) {
        return null
    }
    // console.log(balance);
    return (


        <Tabs bgColor={"white"} px="5px" colorScheme="purple" variant='enclosed'>
            <TabList>
                {
                    Object.keys(balance).map((o) => {
                        return (
                            <Tab _selected={{ color: 'white', bg: 'green.500' }}>
                                {o}
                            </Tab>
                        )
                    })
                }
            </TabList>

            <TabPanels bgColor="white">
                {
                    Object.keys(balance).map((o, i) => {
                        let Options = JSON.parse(JSON.stringify(lineChartOptionsTotalSpent));
                        Options["xaxis"].categories = Object.values(balance[o].daily.dates);
                        //console.log(Options);
                        let data = []

                        Object.keys(balance[o].daily).map(d => {
                            let obj = { name: "", data: [] }
                            obj.name = d;

                            try {
                                if (d !== "dates") {
                                    obj.data = Object.values(balance[o].daily[d])
                                    data.push(obj);
                                    console.log("daaataat", data);
                                }
                            } catch (e) { }
                        });
                        return (

                            <TabPanel>
                                {
                                    data.map(x => {
                                        let arr = []
                                        arr.push(x);
                                        console.log("data",x);
                                        return (
                                            <>
                                                <Tag size='lg' colorScheme='green' borderRadius='full'>
                                                    <TagLabel>{x.name}</TagLabel>
                                                </Tag>
                                                <div style={{ padding: '10px', margin: '0px', margin: '5px' }}>
                                                    {/* {setChart(arr[0].data,Options)} */}

                                                    <LineChart
                                                        chartData={arr}
                                                        chartOptions={Options}
                                                        
                                                    />
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div>
                                    <Tag size='lg' colorScheme='red' borderRadius='full'>
                                        <TagLabel>Comparison</TagLabel>
                                    </Tag>
                                </div>
                                <div style={{ padding: '10px', margin: '0px', margin: '5px' }}>
                                    <LineChart
                                        chartData={data}
                                        chartOptions={Options}
                                    />
                                </div>
                            </TabPanel>

                        )
                    })
                }
            </TabPanels>



        </Tabs>

    )
}


function Volumes(volume) {
    if (volume === undefined) {
        return null
    }
    return (


        <Tabs bgColor={"white"} px="5px" colorScheme="purple" variant='enclosed'>
            <TabList>
                {
                    Object.keys(volume).map((o) => {
                        return (
                            <Tab _selected={{ color: 'white', bg: 'green.500' }}>
                                {o}
                            </Tab>
                        )
                    })
                }
            </TabList>

            <TabPanels bgColor="white">
                {
                    Object.keys(volume).map((o, i) => {

                        return (

                            <TabPanel>

                                <TableContainer>
                                    <Table variant='simple' colorScheme='orange'>
                                        <Thead>
                                            <Tr>
                                                <Th>
                                                    Date
                                                </Th>
                                                <Th>
                                                    Volume
                                                </Th>


                                            </Tr>{

                                            }
                                        </Thead>

                                        <Tbody>
                                            {
                                                Object.keys(volume[o]).reverse().map((ob, i) => {

                                                    //console.log(d.lo())
                                                    //v++;
                                                    return (

                                                        <Tr>
                                                            <Td>
                                                                {formatDate(ob)}
                                                            </Td>
                                                            <Td>
                                                                {

                                                                    Object.keys(volume[o][ob]).map(x => {

                                                                        return (
                                                                            <Stat style={{ padding: '10px', margin: '4px', background: '#f5f4f4' }}>
                                                                                <StatLabel>{x}</StatLabel>
                                                                                <StatNumber>{volume[o][ob][x]}</StatNumber>
                                                                                <StatHelpText>
                                                                                    {o} volume of {x}
                                                                                </StatHelpText>
                                                                            </Stat>
                                                                        )
                                                                    })



                                                                }

                                                            </Td>


                                                        </Tr>

                                                    )

                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                            </TabPanel>

                        )
                    })
                }
            </TabPanels>



        </Tabs>

    )
}
function formatDate(timestamp) {
    const date = new Date(timestamp*1);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  
}

export default UserDashboard;