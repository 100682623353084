// Chakra imports
import {
  Box, Divider, Tag, Avatar, TagLabel, Badge,
  Text,
  Icon,
  useColorModeValue,
  Stack
} from "@chakra-ui/react";
import Card from 'components/card/Card'
import Footer from "components/footer/FooterAdmin.js";
import React, { useState, useContext } from "react";
import MainDashboard from "views/admin/default";
import Marketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTable from 'views/admin/dataTables';
import { Scrollbars } from "react-custom-scrollbars-2";


import { GlobalContext } from "index";
import { Redirect } from "react-router-dom";
import ManageUsers from "views/ManageUsers";
import PaymentReports from "views/PaymentReports";
import ManagePaymentRequest from "views/ManagePaymentRequests";
import UserDashboard from "views/UserDashboard";

// Custom Chakra theme

export default function Dashboard(props) {
  const { isLoading, user, setIsLoading, setUser } = useContext(GlobalContext);
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("#f4f7fe", "navy.800");
  let sidebarMargins = "0px";

  const RoleFunctions = () => {

    return (
      <Stack mt="20vh" align="center">
        {/* <Card p='8px' align='center' direction='column' w='100%' bgColor="white" onClick={() => { setUser(null) }}>
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Admin Dashboard
          </Text>
        </Card> */}
        <Card p='8px' align='center' direction='column' w='100%' bgColor="white">
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Manage Users
          </Text>
        </Card>
        <Card p='8px' align='center' direction='column' w='100%' bgColor="white">
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Approve Funds
          </Text>
        </Card>
        <Card p='8px' align='center' direction='column' w='100%' bgColor="white">
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Manage Funds
          </Text>
        </Card>
        <Card p='8px' align='center' direction='column' w='100%' bgColor="white">
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Queries
          </Text>
        </Card>
        <Card p='8px' align='center' direction='column' w='100%' bgColor="white" onClick={() => setUser(null)}>
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Logout
          </Text>
        </Card>
      </Stack>
    )
  }
  if (user == null) {
    return (
      <Redirect to="/" />
    )
  }
  return (
    <Box>
      <Box
        float='right'
        minHeight='100vh'
        height='100%'
        overflow='auto'
        position='relative'
        maxHeight='100%'
        w={{ base: "100%", xl: "calc( 100% )" }}
        maxWidth={{ base: "100%", xl: "calc( 100%  )" }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'>



        <Divider />

        <Tag mx="10px" my="15px" size='lg' colorScheme='grey' borderRadius='full'>
          <Avatar
            src=''
            size='md'
            name='Segun Adebayo'
            ml={-1}
            mr={2}
          />

          <TagLabel>{user.UserName}</TagLabel>
          <Badge ml='50' colorScheme='green'>
            {user.Role}
          </Badge>
          <Badge ml='50' colorScheme='blue'>
            {user.UUID}
          </Badge>
         
        </Tag>
        <Card p='8px' align='center' direction='column' w='140px' bgColor="white" onClick={() => setUser(null)}>
          <Text fontWeight="bold" fontSize='18px' color='Black'>
            Logout
          </Text>
        </Card>
        <Divider />
        
         <UserDashboard/>
            
        
     
      </Box>

    </Box>
  );
}
